import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Isaiah Toth', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Isaiah Toth',
  subtitle: "and I'm a",
  img: 'profile2.jpg',
  finale: 'Web Developer 🚀 ',
  description:
    "Working with Javascript, ReactJS, and NodeJS, I'm a passionate Full Stack Developer and serious nerd 🤓 at heart.",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I love designing and building software that makes people say "Wow!"',
  paragraphTwo:
    "Most of my experience is working in frontend and backend development within an agile environment. I've held roles from junior developer to tech lead projects with clients like Build-A-Bear, PEETS Cofee, and Coke-A-Cola.",
  paragraphThree:
    'I am always looking for new opportunities to build amazing websites or applications for companies who want to make a huge impact in their field!',
  resume: 'https://drive.google.com/file/d/1a60jt7ypn5VhqXqA1umQ7OsMsB_oBDr9/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'instagramclone.jpg',
    title: 'Reactjs Instagram Clone',
    info: `An Instagram App I built using React JS! This was my first full React app and I loved every second. The  challenge here was to setup the firebase authentication so that each user account could post.`,
    info2: `Features include Login/Authentication, Post creation, comments, firebase backend, dark/light theme switching options`,
    url: 'https://react-ig-clone-bc11b.web.app/',
    repo: 'https://github.com/itoth43/instagram-react-clone', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project4.jpg',
    title: 'Chat App MERN Stack',
    info:
      'This project was built with the MERN stack (MongoDB, ExpressJS, ReactJS, and NodeJS) using firebase, axios, pusher, and mongoose as well.',
    info2: '',
    url: 'https://whatsapp-mern-21da1.web.app',
    repo: 'https://github.com/itoth43/chatapp-mern-frontend', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'studiable1.jpg',
    title: '"Studiable"',
    info: `A simple yet fun and useful studying tool that will help you set the mood for studying, programming, or even just working through out your day. This app was built with HTML5, CSS3, and (Vanilla) Javascript.`,
    info2: `Choose your ideal environment, from a jazzy coffee shop, a lofi home office, or a classical library!`,
    url: 'https://studiable.netlify.app',
    repo: 'https://github.com/itoth43/studiable', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.jpg',
    title: 'AlphaSerenityWellness',
    info: `A website created with HTML, CSS, and (Vanilla) Javascript in 2017 to promote and sell services and a health product created by a wellness comapny.`,
    info2: `Their product is mostly sold on ebay, but very well known and well reviewed, this client decided to move to a website as well!`,
    url: 'http://alphaserenitywellness.com',
    repo: '', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'project3.jpg',
  //   title: 'Javascript 30 Challenge',
  //   info: 'Ongoing, adding new projects from this challenge weekly.',
  //   info2: '',
  //   url: 'https://js30-drumkit.netlify.app',
  //   repo: 'https://github.com/itoth43/javascript30-drumkit', // if no repo, the button will not show up
  // },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Future Project (WIP)',
    info: 'Details to come...',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to Discuss an Idea?',
  btn: '',
  email: 'IsaiahDToth@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/isaiahtoth/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/itoth43',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
